import { Controller } from 'stimulus'; 

export default class extends Controller {
  static targets = ["element", "form"]

  connect() {
    const stripe = Stripe(this.elementTarget.dataset.stripeTokenPublicKey)

    const elements = stripe.elements({
	    fonts: [{
        cssSrc: "https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap"
      }]
    })

    const style = {
      base: {
        color: "#32325d",
        fontWeight: '500',
        fontFamily: "'PT Sans', sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "14px",
	':-webkit-autofill': {
          color: '#6b7280',
        },
        '::placeholder': {
          color: "#6b7280"
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    };
    const card = elements.create("card", { style: style })
    card.mount("#card-element")

    const tokenOverride = this.elementTarget.dataset.override
    const formTarget = this.formTarget
    const button = document.querySelector('[name="commit"]');
    button.addEventListener("click", function(event) {
      event.preventDefault()

      const isProductionEnvironment = document.head.querySelector("meta[name=rails_env]").content === 'production'
      stripe.createToken(card).then(function(result) {
      const displayError = document.getElementById("card-error")

        if (result.error) {
          displayError.textContent = result.error.message
        } else {
          displayError.textContent = ''

          let token = result.token.id
          if (!isProductionEnvironment) {
            token = tokenOverride || "tok_visa"
          }

          document.getElementById("user_stripe_token").value = token
          formTarget.submit()
        }
      })
    })
  }
}
